import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import ReferralCard from '../../card/ReferralCard';
import ButtonCtaReferral from '../../button/ButtonCtaReferral';
import { CUSTOM_EVENT, pushEvent } from '../../../configs/clevertap';
import GTM_TRACKERS from '../../../../constants/gtm-trackers';

export default function ResultSimulation() {
  return (
    <ReferralCard
      title="Dapatkan Keuntungan Hingga Jutaan Rupiah!"
    >
      <div className="flex flex-col gap-y-6 my-4 px-4">
        <div className="flex flex-col gap-y-6 my-4 mx-6">
          <h2 className="text-2xl text-center font-bold text-blue-light40">Rp 3 Juta/<span className="text-xl align-bottom">periode</span></h2>
          {/* eslint-disable-next-line max-len */}
          <div className="flex flex-col gap-y-4 lg:flex-row lg:justify-between lg:gap-x-4 lg:border-[1px] lg:border-secondary-200 lg:rounded-xl lg:p-4 lg:divide-x lg:divide-dashed lg:divide-secondary-200">
            <div className="flex gap-x-4 md:gap-x-2 p-2">
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 text-white bg-blue-light40 p-1 rounded-full"
              />
              <div className="flex flex-col gap-y-4">
                <div className="flex flex-col">
                  <p className="w-60 text-sm font-semibold lg:w-40">
                    Komisi 20 teman:
                  </p>
                  <p className="w-60 text-sm font-semibold lg:w-40">
                    20 x Rp 100.000 = Rp 2.000.000
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="w-60 text-sm font-semibold lg:w-40">
                    Bonus tambahan:
                  </p>
                  <p className="w-60 text-sm font-semibold lg:w-40">
                    &#40;20/5&#41; x Rp 250.000 = Rp 1.000.000
                  </p>
                </div>
              </div>
            </div>
            <div className="flex gap-x-4 md:gap-x-2 p-2">
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 text-white bg-blue-light40 p-1 rounded-full"
              />
              <p className="w-60 text-sm font-semibold lg:w-40">
                Kesempatan bonus tambahan Rp 250.000 berlaku untuk setiap kelipatan 5 teman.
              </p>
            </div>
            <div className="flex gap-x-4 md:gap-x-2 p-2">
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 text-white bg-blue-light40 p-1 rounded-full"
              />
              <p className="w-60 text-sm font-semibold lg:w-40">
                Tidak ada batasan keuntungan selama periode.
              </p>
            </div>
          </div>
          <p className="text-sm font-semibold lg:text-center">Tunggu apalagi, Yuk Daftar dan Dapatkan Berbagai Benefitnya!</p>
        </div>
        <div className={`flex justify-center ${GTM_TRACKERS.REFERRAL.DAFTAR_SEKARANG}`}>
          <ButtonCtaReferral
            isInternal
            onClick={() => pushEvent(CUSTOM_EVENT.DAFTAR_SEKARANG_DETAIL)}
            to="/referral/register"
            text="Daftar Sekarang"
            className="md:w-80"
          />
        </div>
      </div>
    </ReferralCard>
  );
}
