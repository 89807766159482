import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import SimpleNavbar from '../../components/navbar/SimpleNavbar';
import HeroReferral from '../../components/hero/HeroReferral';
import Advantage from '../../components/referralContents/learnMore/Advantage';
import ResultSimulation from '../../components/referralContents/learnMore/ResultSimulation';
import SimpleFooter from '../../components/footer/SimpleFooter';
import TableBonus from '../../components/referralContents/learnMore/TableBonus';
import SEO from '../../SEO';

export default function LearnMore() {
  return (
    <>
      <SEO
        title="DepositoBPR Referral Program | Pelajari Lebih Lanjut"
        metaDescription="DepositoBPR Referral Program #AjakTemanDapatBonus Pelajari Lebih Lanjut"
      />
      <div className="flex flex-col items-center">
        <div className="w-full bg-blue-light40">
          <SimpleNavbar />
          <main className="flex flex-col pb-4 relative">
            <div className="hidden md:block absolute left-0 top-72 h-full">
              <div className="w-full sticky top-0 z-0">
                <StaticImage
                  src="../../images/referral/big-vector.svg"
                  alt="big-vector"
                />
              </div>
            </div>
            <div className="hidden md:block absolute left-0 h-full">
              <div className="sticky top-0 z-0">
                <StaticImage
                  src="../../images/referral/coins.webp"
                  alt="coins"
                  className="w-screen"
                />
              </div>
            </div>
            <article className="md:hidden h-[620px] relative flex flex-col items-center md:items-start gap-y-6 py-6">
              <HeroReferral />
            </article>
            <article className="hidden relative md:flex justify-between gap-y-4 py-6 lg:mx-64 md:mx-20">
              <div className="flex flex-col">
                <HeroReferral />
              </div>
              <div />
              <StaticImage
                src="../../images/referral/hero-desktop.webp"
                alt="hero-image"
                className="w-[400px] absolute bottom-0 -right-10 z-10"
              />
            </article>
            <div className="flex flex-col gap-y-4 mx-4 z-10 lg:mx-64 md:mx-20">
              <ResultSimulation />
              <TableBonus />
              <Advantage />
            </div>
          </main>
          <SimpleFooter />
        </div>
      </div>
    </>
  );
}
