import React from 'react';

const rowsBonus = [
  {
    friends: 1,
    comission: 'Rp100.000',
    multiple: '-',
    accumulation: 'Rp100.000',
  },
  {
    friends: 5,
    comission: 'Rp500.000',
    multiple: 'Rp250.000',
    accumulation: 'Rp750.000',
  },
  {
    friends: 10,
    comission: 'Rp1.000.000',
    multiple: 'Rp500.000',
    accumulation: 'Rp1.500.000',
  },
  {
    friends: 20,
    comission: 'Rp2.000.000',
    multiple: 'Rp1.000.000',
    accumulation: 'Rp3.000.000',
  },
];

export default function TableBonus() {
  return (
    <article className="w-full bg-white border-[1px] border-green-light40 rounded-lg">
      <table className="w-full">
        <thead>
          {/* eslint-disable-next-line max-len */}
          <tr className="px-1 pb-1 bg-gradient-to-b from-yellow to-yellow-100 rounded-lg flex justify-between items-center p-2 border-x border-b border-dashed border-x-gray-dark90 border-b-gray-dark90 rounded-b-lg md:justify-center md:gap-x-16">
            <th className="w-1/4 text-sm text-center text-blue-light40 font-semibold">
              Teman yang diajak
            </th>
            <th className="w-1/4 text-sm text-center text-blue-light40 font-semibold">
              Bonus Komisi
            </th>
            <th className="w-1/4 text-sm text-center text-blue-light40 font-semibold">
              Bonus Kelipatan
            </th>
            <th className="w-1/4 text-sm text-center text-blue-light40 font-semibold">
              Akumulasi
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-yellow-200">
          {rowsBonus.map((row) => (
            <tr
              key={row.friends}
              className="flex justify-between px-2 py-4 md:justify-center md:gap-x-16"
            >
              <td className="w-1/4 text-sm text-center font-bold">
                {row.friends} Teman
              </td>
              <td className="w-1/4 text-sm text-center font-normal">
                {row.comission}
              </td>
              <td className="w-1/4 text-sm text-center font-normal">
                {row.multiple}
              </td>
              <td className="w-1/4 text-sm text-center font-normal">
                {row.accumulation}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </article>
  );
}
